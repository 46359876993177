





import { Component, Prop, Vue } from "vue-property-decorator";
import { ActionMethod } from "vuex";
import { Action } from "vuex-class";

@Component
export default class Card extends Vue {
  @Action('logout', { namespace: 'users' }) logout: ActionMethod;

  mounted() {
    this.logout().then(() => {
      this.$router.push('/')
    })
  }
}
